import { clientOnly } from "@solidjs/start";
import { type Component, lazy } from "solid-js";

export function clientLazy<T extends Component<any>>(
  f: () => Promise<{
    default: T;
  }>,
) {
  return lazy(() => Promise.resolve({ default: clientOnly(f) }));
}
